import React, { Component, useState } from 'react';
import Nav from "../components/nav";
import Footer from "../components/footer";
import Hero from "../components/hero";
import { Helmet } from "react-helmet";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";

import image1 from "../images/chirophysio/image1.png"
import image2 from "../images/chirophysio/image2.png"
import image3 from "../images/chirophysio/image3.jpg"
import image4 from "../images/chirophysio/image4.png"
import imageactive1 from "../images/active-1.png"
import imageactive2 from "../images/active-2.png"
import imageactive3 from "../images/active-3.png"
import Arrow from "../images/arrow-right-white.svg"
import { useCollapse } from "react-collapsed";

import LogoWhite from "../images/header-logo.png"
import {Link} from 'gatsby'
import ExpandArrow from "../images/expand-arrow.png"
import TopBar from "../components/top-bar";
import OpenGraphLogo from "../images/open-graph-logo.png"

function NavActiveCare(props) {
  let highlight = "";
  if (props.home === true){
      highlight = "border-b-4 border-b-accent"
  }
  function ChangeCheckbox(){
      const element = document.getElementById('menu-toggle');
      element.checked = !element.checked;
  }

  function OpenMobileSubMenuServices(){
      OpenMobileSubMenu("mobile-services");
  }

  function OpenMobileSubMenuAbout(){
      OpenMobileSubMenu("mobile-about");
  }

  function OpenMobileSubMenu(id_of_elem){
      const element = document.getElementById(id_of_elem);
      if (element.style.display === "none") {
          element.style.display = "block";
      } else {
          element.style.display = "none";
      }
  }

  // function MakeVisible(){
  //     const element = document.getElementById("big-group");
  //     element.classList.remove("hidden");
  // }
  //
  // function MakeInvisible(){
  //     const element = document.getElementById("big-group");
  //     element.classList.add("hidden");
  // }

  return (
      <>
      <div className={"bg-white h-16 lg:h-20 sticky top-0 transition duration-500 z-10"}>
          <nav className="flex justify-between h-full m-auto z-10">
              <Link className="h-12 lg:h-20 w-auto my-auto pl-4 z-10" to={"/"}>
                  <img className="h-12 lg:h-20 lg:p-3 lg:pl-10 w-auto my-auto pl-4 z-10" alt="kinetic impact logo" width="365"
                       height="95"
                       src={LogoWhite}/></Link>

              <ul className="text-xl justify-between items-center hidden lg:flex lg:ml-10 z-20 pr-4 lg:pr-20 whitespace-nowrap">
                  <li className={highlight + " hover:bg-accent px-3 h-full w-full flex justify-center items-center"}><Link to="/">Home</Link></li>
                  <li className="group relative h-full w-full flex align-middle items-center  hover:bg-accent px-3">
                      <div className={"bg-accent absolute w-auto h-auto left-0 top-20 group-hover:visible invisible"} >{/*onMouseLeave={MakeInvisible}*/}
                          <Link to={"/chiropractic-and-physical-therapy"}><div className={"p-4 hover:bg-gray-100"}><p>Chiropractic & Physical Therapy</p></div></Link>
                          <Link to={"/return-to-sport-testing"}><div className={"p-4 hover:bg-gray-100"}><p>Return to Sport Testing</p></div></Link>
                          <Link to={"/one-on-one-coaching"}><div className={"p-4 hover:bg-gray-100"}><p>1 on 1 Personal Training</p></div></Link>
                          <Link to={"/small-group-training"}><div className={"p-4 hover:bg-gray-100"}><p>Semi Private Training</p></div></Link>
                          <Link to={"/youth-group-class"}><div className={"p-4 hover:bg-gray-100"}><p>Youth Athletic Development</p></div></Link>

                          {/*<div className={"p-4 flex justify-between select-none hover:bg-gray-100 peer"} onClick={OpenSubMenuGroup}><p>Group Classes</p><img className={"w-5 h-5 mt-2"} src={Arrow} alt={"arrow"} /></div>*/}
                          {/*<div id={"big-group"} className={"w-full bg-white peer-hover:block hover:block hidden"} onMouseEnter={MakeVisible}>*/}
                          {/*    <Link to={"/youth-group-class"}><div className={"hover:bg-gray-100"}><p className={"p-4 pl-10"}>Youth Group Classes</p></div></Link>*/}
                          {/*    <Link to={"/adult-group-class"}><div className={"hover:bg-gray-100"}><p className={"p-4 pl-10"}>Adult Group Classes</p></div></Link>*/}
                          {/*</div>*/}
                          {/*<Link to={"/wellness-and-recovery"}><div className={"p-4 hover:bg-gray-100"}><p>Wellness & Recovery</p></div></Link>*/}

                      </div>
                      <Link className={""} to="">Services</Link></li>
                  <li className="group relative h-full w-full flex align-middle items-center  hover:bg-accent px-3">
                      <div className={"bg-accent absolute w-auto h-auto left-0 top-20 group-hover:visible invisible"} >{/*onMouseLeave={MakeInvisible}*/}
                          <Link to={"/about"}><div className={"p-4 hover:bg-gray-100"}><p>About Us</p></div></Link>
                          <Link to={"/testimonials"}><div className={"p-4 hover:bg-gray-100"}><p>Testimonials</p></div></Link>
                          <Link to={"/frequently-asked-questions"}><div className={"p-4 hover:bg-gray-100"}><p>FAQs</p></div></Link>
                      </div>
                      <Link className={""} to="">About</Link></li>
                  {/*<li className=" hover:bg-accent px-3 h-full flex align-middle items-center"><Link to="/about">About</Link></li>*/}
                  <li className=" hover:bg-accent px-3 h-full flex align-middle items-center"><Link to="/contact">Contact</Link></li>
                  <li className=" hover:bg-accent px-3 h-full flex align-middle items-center"><Link to="/blog">Blog</Link></li>
                  {/* <li className=" hover:bg-accent px-3 h-full flex align-middle items-center"><Link to="/low-back-program">Low Back Program</Link></li> */}
              </ul>

              <div className="relative lg:hidden p-4 pl-0">
                  <label htmlFor="menu-toggle"></label>
                  <input id="menu-toggle"
                         className="absolute block cursor-pointer w-12 top-3 h-12 z-[1000] opacity-0 peer select-none"
                         type="checkbox"/>
                  <div className={"fixed left-0 right-0 bottom-0 top-16 w-full transition duration-300 opacity-0 peer-checked:opacity-100 peer-checked:visible bg-accent invisible z-10000"}>
                      <Link to={"/"}><div className={"p-8 py-5 pt-24 border-b border-b-black/25 text-3xl"}>Home</div></Link>
                      <div className={"p-8 py-5 border-b border-b-black/25 text-3xl flex justify-between"} onClick={OpenMobileSubMenuServices}><p>Services</p><img className={"w-5 h-5 mt-2"} src={ExpandArrow} alt={"arrow"} /></div>
                      <div id={"mobile-services"} className={"w-full bg-white"} style={{display: "none"}}>
                          <Link to={"/chiropractic-and-physical-therapy"}><p className={"text-2xl p-4 pl-11"}>Chiropractic & Physical Therapy</p></Link>
                          <Link to={"/return-to-sport-testing"}><p className={"text-2xl p-4 pl-11"}>Return to Sport Testing</p></Link>
                          <Link to={"/one-on-one-coaching"}><p className={"text-2xl p-4 pl-11"}>1 on 1 Personal Training</p></Link>
                          <Link to={"/small-group-training"}><p className={"text-2xl p-4 pl-11"}>Semi Private Training</p></Link>
                          <Link to={"/youth-group-class"}><p className={"text-2xl p-4 pl-11"}>Youth Athletic Development
                          </p></Link>
                          {/*<div className={"text-2xl p-4 pl-11 flex justify-between"} onClick={OpenMobileSubMenuGroup}><p>Group Classes</p><img className={"w-5 h-5 mt-2"} src={Arrow} alt={"arrow"} /></div>*/}
                          {/*<div id={"mobile-group"} className={"w-full bg-white "} style={{display: "none"}}>*/}
                          {/*    <Link to={"/youth-group-class"}><p className={"text-2xl p-4 pl-16"}>Youth Group Classes</p></Link>*/}
                          {/*    <Link to={"/adult-group-class"}><p className={"text-2xl p-4 pl-16"}>Adult Group Classes</p></Link>*/}
                          {/*</div>*/}
                          {/*<Link to={"/wellness-and-recovery"}><p className={"text-2xl p-4 pl-11"}>Wellness & Recovery</p></Link>*/}

                      </div>
                      <div className={"p-8 py-5 border-b border-b-black/25 text-3xl flex justify-between"} onClick={OpenMobileSubMenuAbout}><p>About</p><img className={"w-5 h-5 mt-2"} src={ExpandArrow} alt={"arrow"} /></div>
                      <div id={"mobile-about"} className={"w-full bg-white"} style={{display: "none"}}>
                          <Link to={"/about"}><p className={"text-2xl p-4 pl-11"}>About Us</p></Link>
                          <Link to={"/testimonials"}><p className={"text-2xl p-4 pl-11"}>Testimonials</p></Link>
                          <Link to={"/frequently-asked-questions"}><p className={"text-2xl p-4 pl-11"}>FAQs</p></Link>

                      </div>
                      <Link to="/contact"><div className={"p-8 py-5 border-b border-b-black/25 text-3xl"}>Contact</div></Link>
                      <Link to="/blog"><div className={"p-8 py-5 border-b border-b-black/25 text-3xl"}>Blog</div></Link>
                      {/* <Link to="/low-back-program"><div className={"p-8 py-5 border-b border-b-black/25 text-3xl"}>Low Back Program</div></Link> */}
                  </div>
                  <div className={"fixed w-full h-full bg-accent peer-checked:visible hidden"}>
                      <p>bla bla</p>
                  </div>

                  {/*<div className="fixed w-48 -top-4 -right-80 peer-checked:-translate-x-80 bg-white antialiased transition duration-500 ease-[cubic-bezier(0.77,0.02,0.05,1.0)] dark:bg-gray-900 z-10">
                      <div className="flex-col justify-evenly mt-32 mb-16 text-gray-500 dark:text-white">
                          <Link to="/" >
                              <div onClick={ChangeCheckbox}
                                  className="p-4 mr-4 text-3xl text-right font-bold hover:text-gray-400 active:text-gray-400">Home
                              </div>
                          </Link>
                          <Link to="/about">
                              <div
                                  className="p-4 mr-4 text-3xl text-right font-bold hover:text-gray-400 active:text-gray-400">About
                              </div>
                          </Link>
                          <Link to="/#services">
                              <div onClick={ChangeCheckbox}
                                  className="p-4 mr-4 text-3xl text-right font-bold hover:text-gray-400 active:text-gray-400">Services
                              </div>
                          </Link>
                          <Link to="/comparison">
                              <div
                                  className="p-4 mr-4 text-3xl text-right font-bold hover:text-gray-400 active:text-gray-400">Before & After
                              </div>
                          </Link>
                          <Link to="/hiring">
                              <div
                                  className="p-4 mr-4 text-3xl text-right font-bold hover:text-gray-400 active:text-gray-400">Hiring
                              </div>
                          </Link>
                          <Link to="/contact">
                              <div
                                  className="p-4 mr-4 text-3xl text-right font-bold hover:text-gray-400 active:text-gray-400">Contact
                              </div>
                          </Link>
                      </div>
                  </div>*/}
                  <div className="relative  z-20 w-9 h-0.5 top-1.5 mb-2   bg-black transition rotate-0 duration-500  peer-checked:bg-gray-700 peer-checked:-translate-x-1 peer-checked:rotate-45 duration-300 origin-[11%_100%]"></div>
                  <div className="relative  z-20 w-7 h-0.5 top-1.5 mb-2 ml-2   bg-black peer-checked:opacity-0 peer-checked:scale-0 transition duration-300 origin-[0%_100%]"></div>
                  <div className="relative  z-20 w-9 h-0.5  top-1.5 bg-black transition rotate-0 duration-300  peer-checked:bg-gray-700 peer-checked:-rotate-45 duration-500 origin-[11%_100%] peer-checked:-translate-x-0.5"></div>
                  <div id="mobile-nav-click-off" className="fixed w-full h-full bg-transparent top-0 left-0 -z-10 hidden peer-checked:block" onClick={ChangeCheckbox}></div>
              </div>
          </nav>
      </div>
      </>

  )
}

function PayPalButtonStudent() {
    const addEmailToActiveCampaign = async (email) => {
        fetch(`/.netlify/functions/addEmailToActiveCampaign?email=${email}`)
        .then(response => response.text())
        .then(data => console.log(data));
      };

  return (
    <div className='w-full lg:w-[500px] p-5'>
    <PayPalScriptProvider options={{ "client-id": "AYGIC1nmdxe_eCE7-RDN_XoGcS269SRJ-wAXIuMx9-r_gEPVq5SvldtFQd3kwVGM-h4ImRGZ85RtakqV" }}>
      <PayPalButtons
      style={{ layout: "vertical" }}
        createOrder={(data, actions) => {
          return actions.order.create({
            purchase_units: [
              {
                amount: {
                  value: "200.00",
                },
              },
            ],
            application_context: {
                shipping_preference: "NO_SHIPPING"
            }
          });
        }}
        onApprove={(data, actions) => {
            return actions.order.capture().then(function(details) {
              // Call your function to add email to ActiveCampaign
              addEmailToActiveCampaign(details.payer.email_address);
            });
          }}
      />
    </PayPalScriptProvider>
    </div>
  );
}


function PayPalButtonGeneral() {
    const addEmailToActiveCampaign = async (email) => {
        fetch(`/.netlify/functions/addEmailToActiveCampaign?email=${email}`)
        .then(response => response.text())
        .then(data => console.log(data));
      };
    return (
        <div className='w-full lg:w-[500px] p-5'>
      <PayPalScriptProvider options={{ "client-id": "AYGIC1nmdxe_eCE7-RDN_XoGcS269SRJ-wAXIuMx9-r_gEPVq5SvldtFQd3kwVGM-h4ImRGZ85RtakqV" }}>
        <PayPalButtons
        style={{ layout: "vertical" }}
          createOrder={(data, actions) => {
            return actions.order.create({
              purchase_units: [
                {
                  amount: {
                    value: "300.00",
                  },
                },
              ],
              application_context: {
                  shipping_preference: "NO_SHIPPING"
              }
            });
          }}
          onApprove={(data, actions) => {
            return actions.order.capture().then(function(details) {
              // Call your function to add email to ActiveCampaign
              addEmailToActiveCampaign(details.payer.email_address);
            });
          }}
        />
      </PayPalScriptProvider>
      </div>
    );
  }

const TwoBuyButtons = () => {
    const [isVisible, setIsVisible] = useState(true)
    const [isSecondVisible, setIsSecondVisible] = useState(true)
    const [isGeneral, setIsGeneral] = useState(false)
    const [isStudent, setIsStudent] = useState(false)
  
    return (
      <>
        {isVisible ? (
            <p onClick={() => setIsVisible(false)} className={"bg-accent text-black px-9 py-6 m-2 text-3xl hover:bg-gray-100 group"}> Register Now <img loading={"eager"} className={"inline pl-2 group-hover:translate-x-3 duration-150"} src={Arrow} alt={"arrow"} />
            </p>
        ) : (
        <>
            {isSecondVisible ? (
            <><a onClick={() => [setIsGeneral(true),setIsSecondVisible(false)]} className={"bg-accent text-black px-9 py-6 m-2 lg:text-3xl text-2xl hover:bg-gray-100 group"}> General Admission <img loading={"eager"} className={"inline pl-2 group-hover:translate-x-3 duration-150"} src={Arrow} alt={"arrow"} />
            </a>
            <a onClick={() => [setIsStudent(true),setIsSecondVisible(false)]} className={"bg-accent text-black px-9 py-6 m-2 lg:text-3xl text-2xl hover:bg-gray-100 group"}> Student Admission <img loading={"eager"} className={"inline pl-2 group-hover:translate-x-3 duration-150"} src={Arrow} alt={"arrow"} />
            </a></>
            ) : (
                null
            )}
            {isGeneral ? (
            <PayPalButtonGeneral/>
            ) : (
                null
            )}
            {isStudent ? (
            <PayPalButtonStudent/>
            ) : (
                null
            )}
            </>
        )}
      </>
    )
  }

  function WaitList(){

    return(
        <form className={"flex flex-col gap-4"} name={"waitlist"} method={"POST"} data-netlify={"true"}>
            <input type="hidden" name="form-name" value="waitlist" />
            <input className={"border active:border-accent border-black p-2 pb-32"} type="hidden" id="note" name="note" placeholder="Note" value="New Building a Framework to Active Care Waitlist Member:" />
            <input className={"border active:border-accent border-black text-black p-2 text-xl"} type="text" id="name" name="name" placeholder="Name" required/>
            <input className={"border active:border-accent border-black text-black p-2 text-xl"} type="email" id="email" name="email" placeholder="Email" required/>
            <input className={"bg-accent text-white p-2 text-2xl cursor-pointer"} type="submit" value="Join Wait List"/>
        </form>
    )
  }

const SoldOut = () => {
    const [isVisible, setIsVisible] = useState(true)
    return(
        <div className={"flex flex-col align-middle items-center border-t-2 border-accent w-full"}>
            <p className={"lg:text-4xl text-3xl text-center pb-6 pt-6 font-bold"}>Sold Out</p>
            {isVisible ? (
                <p onClick={() => setIsVisible(false)} className={"bg-accent text-black px-7 py-4 m-1 lg:text-2xl text-xl hover:bg-gray-100 group cursor-pointer"}> Join Wait List <img loading={"eager"} className={"inline pl-2 group-hover:translate-x-3 duration-150"} src={Arrow} alt={"arrow"} /></p>
            ) : (
                <WaitList/>
                )}
        </div>
    )
}

function BuildingAFrameworkToActiveCare() {
    return (
        <div>
            <Helmet>
                <title>Kinetic Impact | Building a Framework to Active Care</title>
      <meta property='og:type' content='website' />
<meta property='og:title' content='Kinetic Impact | Building a Framework to Active Care' />
<meta property='og:url' content='https://kirehab.com/building-a-framework-to-active-care/' />
<meta property='og:image' content={imageactive1} />
<meta property='og:description' content='
Learn how to go beyond modifying symptoms to create long lasting change with your patients or clients.
This is an overview of how to build a practice that fosters an environment for patients or clients to feel empowered by exercise in a world full of quick fixes and false promises.' />
            </Helmet>
            <NavActiveCare />
            <form className={"flex flex-col gap-4 hidden"} name={"waitlist"} method={"POST"} data-netlify={"true"}>
                <input type="hidden" name="form-name" value="waitlist" />
                <input className={"border active:border-accent border-black p-2 pb-32"} type="hidden" id="note" name="note" placeholder="Note" value="New Building a Framework to Active Care Waitlist Member:" />
                <input className={"border active:border-accent border-black text-black p-2 text-xl"} type="text" id="name" name="name" placeholder="Name" required/>
                <input className={"border active:border-accent border-black text-black p-2 text-xl"} type="email" id="email" name="email" placeholder="Email" required/>
                <input className={"bg-accent text-white p-2 text-2xl cursor-pointer"} type="submit" value="Join Wait List"/>
            </form>
            <div className={"bg-black w-full"}>
                <div className={"max-w-6xl mx-auto flex flex-wrap text-white"}>
                    <div className='w-full p-5 lg:p-10 pb-0'>
                
                </div>
                    <div className='lg:w-1/2 w-full p-5 lg:p-10 pb-0 lg:pb-10'>
                    <p className='text-5xl text-center pb-5 leading-normal'>Building a Framework to <span className='text-accent'>Active Care</span></p>
                        <p className='text-xl pb-3 leading-relaxed'>Learn how to go beyond modifying symptoms to create long lasting change with your patients or clients.</p>
                        <p className='text-xl pb-3 leading-relaxed'>This is an overview of how to build a practice that fosters an environment for patients or clients to feel empowered by exercise in a world full of quick fixes and false promises.</p>
                        <p className='text-xl pb-3 leading-relaxed'>Learn how to address the psychological needs to promote long term healthcare AKA healthspan through physical activity.</p>
                        <p className='text-xl pb-3 leading-relaxed'>From our knowledge and understanding of the evidence on high valued care, we will share how to turn your assessment into a program that gives your client hope and is meaningful to their goal.</p>
                        
                    </div>
                    <div className='lg:w-1/2 w-full p-5 lg:p-10 lg:pt-12 pt-4'>
                        <img className='rounded-xl' src={imageactive1} />
                        
                        <p className='text-2xl pb-3 pt-5 leading-snug'><span className='text-accent font-bold'>Date </span> Saturday, February 17th</p>
                        <p className='text-2xl pb-3 leading-snug'><span className='text-accent font-bold'>Venue </span> Performance Place Sports Care <br/>1525 Mesa Verde Drive East Suite 108 <br/>Costa Mesa, CA 92626</p>
                        <p className='text-2xl pb-1 leading-snug text-accent font-bold'>Pricing</p>
                        <p className='text-2xl pb-3 leading-snug'><span className='text-accent font-bold'>Cost </span> $350 General Admission <br/><span className='pl-[3.75rem]'> $250 Student Admission</span></p>
                        <p className='text-2xl pb-3 leading-snug'><span className='text-accent font-bold'>CEUs </span> Submitted for Approval</p>
                    </div>
                    <div className='py-10 pt-0 w-full flex flex-wrap justify-center'>
                        {/*<TwoBuyButtons/>*/}
                        <SoldOut/>
                            </div>
                </div>
            </div>
            <div className='bg-white w-full'>
                <div className={" max-w-6xl mx-auto w-full p-5 lg:p-10"}>
                    <p className='text-4xl pb-3 leading-relaxed'><span className='text-accent'>Course </span> Structure</p>
                    <p className='text-xl pb-3 leading-relaxed'>We will be diving into the evidence of high valued care in the world of musculoskeletal disorders. This will create the framework for the course as we share how we turn the evidence into a process you can apply with your client and/or patient.</p>
                    <p className='text-xl pb-3 leading-relaxed'>We will cover how we interview our clients, how we assess out clients and how we turn their goals or limitations into their rehab programming for long term results.</p>
                    <p className='text-xl pb-3 leading-relaxed'>This course is designed to provide an intimate learning experience where we encourage questions and dialogue to learn not on with but from each other.</p>
                    
                    <p className='text-4xl pb-3 pt-3 leading-relaxed'><span className='text-accent'>Course </span> Objectives</p>
                    <p className='text-xl pl-3 pb-1 leading-relaxed'><span className='pr-3'>-</span> Promote long term change in a climate with poor healthcare outcomes</p>
                    <p className='text-xl pl-3 pb-1 leading-relaxed'><span className='pr-3'>-</span> Understand pain and how to coach your client</p>
                    <p className='text-xl pl-3 pb-1 leading-relaxed'><span className='pr-3'>-</span> Implement a collaborative approach to behavior change</p>
                    <p className='text-xl pl-3 pb-1 leading-relaxed'><span className='pr-3'>-</span> Pivot from diagnosis to assessment and goal driven care</p>
                    <p className='text-xl pl-3 pb-1 leading-relaxed'><span className='pr-3'>-</span> Understand how to use needs analysis to drive better rehab programming</p>
                    <p className='text-xl pl-3 pb-1 leading-relaxed'><span className='pr-3'>-</span> How to provide the support that builds trust to foster a long term relationship</p>
    
                    <p className='text-4xl pb-3 pt-5 leading-relaxed'><span className='text-accent'>What</span> does this mean for you?</p>
                    <p className='text-xl pb-3 leading-relaxed'>Your clients will WANT to come back and will be happy to adhere to the advice you give them. They become role models for their friends and family and will want to share this experience with everyone they know. You will be unshackled from the need to fix everyone you see because you will be able to show your clients THEY are the superhero and you are the sidekick.</p>
                    
                    <p className='text-4xl pb-3 pt-5 leading-relaxed'><span className='text-accent'>Who</span> is this course open to?</p>
                    <p className='text-xl pb-3 leading-relaxed'>Clinicians (PT, DC, OT, ATC, MD) and coaches looking to provide clients with long term results.</p>
                    
                    <div className='py-10 flex justify-center'>
                        {/*<TwoBuyButtons/>*/}
                        <SoldOut/>
                    </div>
                </div>
            </div>
            <div className={"bg-black w-full"}>
                <div className={"max-w-6xl mx-auto flex flex-wrap text-white"}>
                    <div className='lg:w-1/2 w-full p-5 lg:p-10 flex flex-col justify-center items-center'>
                        <img className='rounded-xl mb-10' src={imageactive2} />
                        <img className='rounded-xl w-3/4' src={imageactive3} />
                    </div>
                    <div className='lg:w-1/2 w-full p-5 lg:p-10 '>
                        <p className='text-5xl pb-5 text-center'>About the <span className='text-accent'>Instructors</span></p>
                        <p className='text-xl pb-3 leading-relaxed'>Dr. Donald Mull and Dr. Ben DeLuca co-founded Kinetic Impact Rehab and Performance in 2017 that currently serves the San Diego Community. They attended Southern California University of Health Sciences together where they received their Doctorate of Chiropractic.
</p>
                        <p className='text-xl pb-3 leading-relaxed'>Their practice has grown from two tables in the corner of a gym to a multidisciplinary clinic-gym hybrid where their mission is to provide relentless support and guide people who have struggled or tried many things on their own through pain or performance/health plateaus, teaching them how to remove physical limitations that feed into a better relationship with physical activity.</p>
                        <p className='text-xl pb-3 leading-relaxed'>Dr. Kevin Leung, DPT joined the team in 2022 and has helped serve this mission as we continue to show people how capable they are while helping them prioritize their long term health to keep them young for life.</p>
                        
                        <div className='py-10 flex flex-col justify-center'>
                            {/*<TwoBuyButtons/>*/}
                            <SoldOut/>
                        </div>
                    </div>


                </div>
            </div>


 



            <Footer />
        </div>
    )
}

export default BuildingAFrameworkToActiveCare
